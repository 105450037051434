import React, {ComponentProps, ReactNode} from 'react';
import Card from "@/Components/Card";
import clsx from "clsx";
import {IconAward, IconHeart, IconLinkPlus, IconPuzzle} from "@tabler/icons-react";
import CompellioSymbol from '../../../assets/compellio-icon.svg?react'
import A, {ALink} from "@/Components/A";
import Motion from "@/Components/Motion";
import NetworkIconsStack from "@/Components/NetworkIconsStack";

function FeatureCardTitle({className, children, icon, iconOverride, postfix, ...props}: ComponentProps<"h2"> & {
    icon: React.ElementType,
    iconOverride?: ReactNode,
    postfix?: ReactNode
}) {
    return (
        <div className="flex flex-col gap-4 mb-4">
            <div>
                {iconOverride || <>
                    <div className="inline-block rounded-full text-brand-600 bg-brand-50 p-3">
                        {React.createElement(icon, {size: 25, height: 25, width: 25})}
                    </div>
                </>}
            </div>
            <div className="flex flex-wrap items-center gap-x-3 gap-y-2">
                <h2 {...props} className={clsx("font-display font-semibold text-xl", className)}>
                    {children}
                </h2>
                {postfix}
            </div>
        </div>
    );
}

export default function Benefits() {

    const supportedChains = ["polygon", "ethereum", "optimism", "avalanche"];

    return (
        <div className="relative mt-12">

            {/* gradient start */}
            {/*<div className="absolute z-0 w-[40%] h-[35%] top-0 pink__gradient" />*/}
            <div className="absolute w-[80%] h-[80%] rounded-full white__gradient bottom-40 pointer-events-none select-none"/>
            {/* gradient end */}

            <div className="relative grid sm:grid-cols-2 gap-2 sm:gap-3 mb-5 text-zinc-900">

                <Motion className="sm:row-span-2">
                    <Card className="flex flex-col text-lg h-full w-full">
                        <FeatureCardTitle icon={IconAward}>Soulbound NFT Issuance</FeatureCardTitle>
                        <p className="text-lg leading-relaxed">
                            AlmaMint binds academic achievements and course completion credentials to unique non-transferable
                            tokens, minted directly into each student’s wallet.
                        </p>

                        <h3 className="text-lg font-semibold mt-8 mb-2">Multi-chain Support</h3>
                        <p className="text-lg leading-relaxed mb-6">AlmaMint supports minting tokens on major EVM compatible chains.</p>

                        <NetworkIconsStack supportedChains={supportedChains} className="mb-2"/>

                        <div className="mt-auto pt-4">
                            <ALink href={route('providers')} externalIcon={true}>Learn more</ALink>
                        </div>
                    </Card>
                </Motion>

                <Motion>
                    <Card className="text-lg w-full h-full">
                        <FeatureCardTitle icon={IconPuzzle}>Composable Verification</FeatureCardTitle>
                        <p className="leading-relaxed">
                            Tokenized credentials conform to a common data scheme, making
                            it simple to interpret and verify by both humans and automated tasks.
                        </p>
                        <div className="mt-auto pt-4">
                            <ALink href={route('providers')} externalIcon={true}>Learn more</ALink>
                        </div>
                    </Card>
                </Motion>

                <Motion>
                    <Card className="text-lg w-full h-full">
                        <FeatureCardTitle icon={IconLinkPlus}>Privacy-first Sharing</FeatureCardTitle>
                        <p className="leading-relaxed">
                            Credential holders can share their AlmaMint token with third-parties while selectively disclosing
                            data, balancing trust, transparency and privacy.
                        </p>

                        <div className="mt-auto pt-4">
                            <ALink href={route('providers')} externalIcon={true}>Learn more</ALink>
                        </div>
                    </Card>
                </Motion>

                <Motion className="sm:col-span-2">
                    <Card className="text-lg w-full h-full">
                        <FeatureCardTitle
                            icon={IconHeart}
                            iconOverride={
                                <div className="inline-block rounded-full bg-[#B8EDFB]/40 p-2">
                                    <CompellioSymbol height={35} width={35}/>
                                </div>
                            }
                        >
                            Hosted by Compellio
                        </FeatureCardTitle>
                        <p className="leading-relaxed mb-3">
                            AlmaMint is designed and built by Compellio, an acknowledged provider of global
                            infrastructure components for bridging the gap between web2 & web3 technologies.
                        </p>
                        <A href="https://compell.io" externalIcon={true}>Learn more</A>
                    </Card>
                </Motion>

            </div>

        </div>
    )
}
