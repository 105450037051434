import Button, {ButtonScale} from "@/Components/Button";
import {ContactDialogContext} from "@/Globals/ContactDialog";
import React, {useContext} from "react";
import Circled from "@/Components/Circled";
import Section from "@/Components/Section";
import NftCard from "@/Partials/Welcome/NftCard";
import {Link} from "@inertiajs/react";
import Motion from "@/Components/Motion";

export default function Hero() {

    const {openDialog: openContactDialog} = useContext(ContactDialogContext);

    return (
        <Section className="flex flex-col sm:flex-row items-center gap-8 pt-16 sm:pt-10 pb-10">

            <Motion>
                <h1 className="font-display font-semibold text-4xl md:text-5xl mb-6 leading-[1.175]">
                    Issue <Circled>Tokenized</Circled> Credentials
                </h1>
                <p className="text-xl leading-relaxed mb-10 text-stone-700">
                    AlmaMint leverages blockchain technologies to issue unique, personalised, and verifiable tokens for
                    your education credentials, course certificates, or digital diplomas.
                </p>
                <div className="flex flex-wrap items-center gap-6">
                    <Button scale={ButtonScale.Large} onClick={openContactDialog} animatedArrowRight={true}>Request Demo</Button>
                    <Link href={route('providers')} className={"font-medium text-stone-800 "}>Learn More</Link>
                </div>
            </Motion>

            <div className="-mx-7 sm:mx-0 shrink-0 hidden sm:block">
                <NftCard className="max-w-xs"/>
            </div>

        </Section>
    )
}
