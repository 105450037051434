import React from "react";
import {motion} from "framer-motion";
import NftMedal4 from "../../../assets/artwork-example4.png";
import Card from "@/Components/Card";
import NetworkIcon from "@/Components/NetworkIcon";
import {PageProps} from "@/types";
import {usePage} from "@inertiajs/react";
import PropertyContainer from "@/Components/PropertyContainer";
import {formatDate} from "@/common";
import {IconShieldLock} from "@tabler/icons-react";

export default function NftCard({ className }: { className?: string }) {

    const network = usePage<PageProps>().props.networks.default;

    const tokenData = {
        tokenIndex: 23,
        courseName: "Web3 Bootcamp",
        recipientName: "Jane Doe",
        imageUrl: NftMedal4,
        imageAlt: "Unique Digital Art",
        issuedOn: new Date(),
    };

    const cardContainerVariants = {
        hidden: { opacity: 0, y: 10 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                delay: 0.2,
                duration: 0.5,
                when: "beforeChildren",
                staggerChildren: 0.1
            }
        },
    };

    // animation elements inside the card
    const itemVariants = {
        hidden: { opacity: 0, y: 10 },
        visible: (i: number) => ({
            opacity: 1,
            y: 0,
            transition: { delay: i * 0.1, type: "spring", stiffness: 100 },
        }),
    };

    return (
        <motion.div variants={cardContainerVariants} initial="hidden" animate="visible" className={className}>

            <Card className="!p-2 relative flex sm:flex-col gap-2 border-stone-900/15">

                <motion.div className="relative aspect-square" custom={0} variants={itemVariants}>

                    <div className="absolute top-0 left-0 p-3 z-10 flex gap-2">
                        <div
                            className="flex items-center justify-center h-[45px] w-[45px] bg-stone-900/20 backdrop-blur text-white rounded-full">
                            <NetworkIcon network={network} size={25} color={false}/>
                        </div>

                        <div
                            className="flex items-center h-[45px] px-3 bg-stone-900/20 backdrop-blur text-white font-display text-xl font-medium tracking-wider rounded-full">
                            #{tokenData.tokenIndex}
                        </div>
                    </div>

                    <div className="absolute bottom-px left-px right-px p-3 z-10 bg-gradient-to-t from-stone-900/40 text-white rounded-b-lg">
                        <motion.div custom={3} variants={itemVariants}>
                            <h4 className="font-display font-semibold text-lg mt-20 leading-tight">{tokenData.courseName}</h4>
                        </motion.div>
                    </div>

                    <div className="rounded-[calc(theme(borderRadius.2xl)-6px)] overflow-hidden aspect-square relative after:w-full after:h-full after:top-0 after:absolute after:border after:border-stone-700/20 after:rounded-[calc(theme(borderRadius.2xl)-6px)]">
                        <img src={tokenData.imageUrl} alt={tokenData.imageAlt} className="w-full"/>
                    </div>

                </motion.div>

                <motion.div custom={4} variants={itemVariants}>
                    <PropertyContainer label={"Recipient"} value={tokenData.recipientName} postfixNode={
                        <IconShieldLock size={20} className="text-stone-700 mx-1"/>
                    }/>
                </motion.div>

                <motion.div custom={5} variants={itemVariants}>
                    <PropertyContainer label={"Issued On"} value={formatDate(tokenData.issuedOn)}/>
                </motion.div>

            </Card>

        </motion.div>
    );
}
