import {PageProps} from "@/types";
import {motion, useAnimation, useInView} from "framer-motion";
import React, {useEffect, useRef} from "react";
import GradientBlue from "../../assets/gradient-blue.png";
import GradientRed from "../../assets/gradient-red.png";
import Hexagon from "../../assets/hexagon-background.svg";
import {Head} from "@inertiajs/react";
import Hero from "@/Partials/Welcome/Hero";
import Benefits from "@/Partials/Welcome/Benefits";
import Contact from "@/Partials/Contact";
import MarketingLayout from "@/Layouts/MarketingLayout";
import Container from "@/Components/Container";
import Header from "@/Components/Header";
import Section from "@/Components/Section";

export default function Welcome({auth}: PageProps) {
    const heroControls = useAnimation();
    const benefitsControls = useAnimation();
    const aboutControls = useAnimation();
    const ctaControls = useAnimation();

    const heroRef = useRef(null);
    const benefitsRef = useRef(null);
    const aboutRef = useRef(null);
    const ctaRef = useRef(null);

    const heroInView = useInView(heroRef, {once: true});
    const benefitsInView = useInView(benefitsRef, {once: true});
    const aboutInView = useInView(aboutRef, {once: true});
    const ctaInView = useInView(ctaRef, {once: true});

    useEffect(() => {
        if (heroInView) {
            heroControls.start("visible");
        }
    }, [heroControls, heroInView]);

    useEffect(() => {
        if (benefitsInView) {
            benefitsControls.start("visible");
        }
    }, [benefitsControls, benefitsInView]);

    useEffect(() => {
        if (aboutInView) {
            aboutControls.start("visible");
        }
    }, [aboutControls, aboutInView]);

    useEffect(() => {
        if (ctaInView) {
            ctaControls.start("visible");
        }
    }, [ctaControls, ctaInView]);

    const sectionVariants = {
        hidden: {opacity: 0, y: 20},
        visible: {
            opacity: 1, y: 0, transition: {
                duration: 1.0,
                delay: 0.2
            }
        }
    };

    return (
        <MarketingLayout withHeader={false}>
            <Head title="Issue Tokenized Credentials"/>

            <div className="relative overflow-hidden sm:overflow-visible rounded-b-2xl sm:rounded-none border-b-2 sm:border-none border-stone-900/10 bg-gradient-to-t from-white sm:bg-none">

                <div
                    className="absolute top-0 left-0 bg-no-repeat bg-right bg-cover w-full min-h-full opacity-80 pointer-events-none select-none">
                    <img src={GradientBlue} alt="Background Gradient"/>
                </div>

                <div
                    className="absolute top-0 right-0 bg-no-repeat bg-right bg-cover w-full min-h-full pointer-events-none select-none">
                    <img src={GradientRed} alt="Background Gradient"/>
                </div>

                <div
                    className="absolute right-0 w-full min-h-full overflow-hidden opacity-20 pointer-events-none select-none">
                    <img src={Hexagon} alt="Hexagon Background" className="w-full h-full object-cover"/>
                </div>

                <Container expanded={true} className="relative">

                    <Header/>

                    <Hero/>

                </Container>

            </div>

            <Container expanded={true}>

                <motion.div
                    ref={heroRef}
                    initial="hidden"
                    animate={heroControls}
                    variants={sectionVariants}
                >

                    <Section className="mt-10 mb-6">

                        <p className="block text-2xl leading-8 my-12">
                            AlmaMint provides a <strong
                            className="text-brand-600 font-medium">cost-effective</strong> solution for <strong
                            className="text-brand-600 font-medium">reducing
                            verification overheads</strong> while unlocking new creative experiences for students.
                        </p>

                    </Section>

                </motion.div>

                <motion.div
                    ref={benefitsRef}
                    initial="hidden"
                    animate={benefitsControls}
                    variants={sectionVariants}
                >
                    <Benefits/>
                </motion.div>

                <motion.div
                    ref={aboutRef}
                    initial="hidden"
                    animate={aboutControls}
                    variants={sectionVariants}
                >
                    <Contact/>
                </motion.div>

            </Container>

        </MarketingLayout>
    );
}
